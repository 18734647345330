import { createRouter, createWebHistory } from "vue-router";
import AppLayout from "@/layouts/App.vue";
import PublicLayout from "@/layouts/Public.vue";
import store from "@/store/index";

const routes = [
  {
    path: "",
    component: PublicLayout,
    redirect: "/login",
    children: [
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/pages/Login.vue"),
      },
    ],
  },
  {
    path: "",
    component: AppLayout,
    meta: { auth: true },
    children: [
      {
        path: "/dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/stocks",
        component: () =>
          import(/* webpackChunkName: "stocks" */ "../views/stocks/Stocks.vue"),
      },
      {
        path: "/stocks/create",
        component: () =>
          import(
            /* webpackChunkName: "stocks-create" */ "../views/stocks/CreateStock.vue"
          ),
      },
      {
        path: "/stocks/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "stocks-create" */ "../views/stocks/EditStock.vue"
          ),
      },
      {
        path: "/reports/summary",
        component: () =>
          import(
            /* webpackChunkName: "summary reports" */ "../views/reports/Summary.vue"
          ),
      },
      {
        path: "/reports/customer/trail-balance",
        component: () =>
          import(
            /* webpackChunkName: "customer trial reports" */ "../views/reports/CustomerTrailBalance.vue"
          ),
      },
      {
        path: "/reports/customer/balance",
        component: () =>
          import(
            /* webpackChunkName: "customer balance reports" */ "../views/reports/CustomerBalance.vue"
          ),
      },
      {
        path: "/reports/aged-customer/analysis",
        component: () =>
          import(
            /* webpackChunkName: "customer balance reports" */ "../views/reports/AgedCustomerReport.vue"
          ),
      },

      {
        path: "/invoices",
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ "../views/invoices/Index.vue"
          ),
      },
      {
        path: "/invoices/:id",
        component: () =>
          import(
            /* webpackChunkName: "invoice view" */ "../views/invoices/View.vue"
          ),
      },
      {
        path: "/purchase-orders",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders" */ "../views/purchase-orders/PurchaseOrders.vue"
          ),
      },
      {
        path: "/purchase-orders/create",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-create" */ "../views/purchase-orders/CreatePurchaseOrder.vue"
          ),
      },
      {
        path: "/purchase-orders/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-edit" */ "../views/purchase-orders/UpdatePurchaseOrder.vue"
          ),
      },
      {
        path: "/vendor-purchase-orders",
        component: () =>
          import(
            /* webpackChunkName: "vendor-purchase-orders" */ "../views/vendor-purchase-orders/PurchaseOrders.vue"
          ),
      },
      {
        path: "/vendor-purchase-orders/create",
        component: () =>
          import(
            /* webpackChunkName: "vendor-purchase-orders-create" */ "../views/vendor-purchase-orders/CreatePurchaseOrder.vue"
          ),
      },

      {
        path: "/vendor-purchase-orders/:orderId/grn",
        component: () =>
          import(
            /* webpackChunkName: "vendor-purchase-orders-grn" */ "../views/vendor-purchase-orders/grn/Index.vue"
          ),
      },
      {
        path: "/vendor-purchase-orders/:orderId/grn/create",
        component: () =>
          import(
            /* webpackChunkName: "vendor-purchase-orders-grn-create" */ "../views/vendor-purchase-orders/grn/Create.vue"
          ),
      },
      {
        path: "/vendor-purchase-orders/:orderId/grn/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "vendor-purchase-orders-grn-update" */ "../views/vendor-purchase-orders/grn/Update.vue"
          ),
      },

      {
        path: "/credit-notes/",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-edit" */ "../views/credit-notes/creditNotesList.vue"
          ),
      },
      {
        path: "/credit-notes/add/",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-edit" */ "../views/credit-notes/createCreditNotes.vue"
          ),
      },
      {
        path: "/purchase-orders/:orderId/collections",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-collections" */ "../views/purchase-orders/collection/Index.vue"
          ),
      },
      {
        path: "/purchase-orders/:orderId/collections/create",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-collection-create" */ "../views/purchase-orders/collection/Create.vue"
          ),
      },
      {
        path: "/collections",
        component: () =>
          import(
            /* webpackChunkName: "sales-collections" */ "../views/collection/Index.vue"
          ),
      },
      {
        path: "/collections/create",
        component: () =>
          import(
            /* webpackChunkName: "sales-collections-create" */ "../views/collection/Create.vue"
          ),
      },
      {
        path: "/collections/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "sales-collections-edit" */ "../views/collection/Edit.vue"
          ),
      },
      {
        path: "/payments",
        component: () =>
          import(
            /* webpackChunkName: "vendor-payments" */ "../views/payments/Index.vue"
          ),
      },
      {
        path: "/payments/create",
        component: () =>
          import(
            /* webpackChunkName: "vendor-payments-create" */ "../views/payments/Create.vue"
          ),
      },
      {
        path: "/sales",
        component: () =>
          import(/* webpackChunkName: "sales" */ "../views/sales/Sales.vue"),
      },
      {
        path: "/expenses",
        component: () =>
          import(
            /* webpackChunkName: "expenses" */ "../views/expenses/Expenses.vue"
          ),
      },
      {
        path: "/expenses/create",
        component: () =>
          import(
            /* webpackChunkName: "expenses-create" */ "../views/expenses/CreateExpense.vue"
          ),
      },
      {
        path: "/expenses/:jnNo",
        component: () =>
          import(
            /* webpackChunkName: "expense-view" */ "../views/expenses/ExpenseView.vue"
          ),
      },
      {
        path: "/expenses/:jnNo/edit",
        component: () =>
          import(
            /* webpackChunkName: "expense-edit" */ "../views/expenses/EditExpense.vue"
          ),
      },
      {
        path: "/expense-heads",
        component: () =>
          import(
            /* webpackChunkName: "expense-heads" */ "../views/expenses/ExpenseHeads.vue"
          ),
      },
      {
        path: "/expense-heads/create",
        component: () =>
          import(
            /* webpackChunkName: "expense-heads-create" */ "../views/expenses/CreateExpenseHead.vue"
          ),
      },
      {
        path: "/settings/users",
        component: () =>
          import(
            /* webpackChunkName: "settings-users" */ "../views/users/Users.vue"
          ),
      },
      {
        path: "/settings/users/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/users/CreateUser.vue"
          ),
      },
      {
        path: "/sku",
        component: () =>
          import(
            /* webpackChunkName: "SkuView" */ "../views/stock/sku/SkuView"
          ),
      },
      {
        path: "/sku/create",
        component: () =>
          import(
            /* webpackChunkName: "Sku-create" */ "../views/stock/sku/CreateSku"
          ),
      },
      {
        path: "/sku/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "Sku-edit" */ "../views/stock/sku/EditSku"
          ),
      },

      {
        path: "/category",
        component: () =>
          import(
            /* webpackChunkName: "Category" */ "../views/stock/category/Category"
          ),
      },
      {
        path: "/category/create",
        component: () =>
          import(
            /* webpackChunkName: "Category-create" */ "../views/stock/category/CreateCategory"
          ),
      },
      {
        path: "/category/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "Category-edit" */ "../views/stock/category/EditCategory"
          ),
      },

      {
        path: "/products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/stock/product/Product"
          ),
      },
      {
        path: "/product/create",
        component: () =>
          import(
            /* webpackChunkName: "product-create" */ "../views/stock/product/CreateProduct"
          ),
      },
      {
        path: "/product/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "product-edit" */ "../views/stock/product/EditProduct"
          ),
      },

      {
        path: "/batches",
        component: () =>
          import(
            /* webpackChunkName: "batches" */ "../views/stock/batch/BatchView"
          ),
      },
      {
        path: "/batches/create",
        component: () =>
          import(
            /* webpackChunkName: "batches-create" */ "../views/stock/batch/CreateBatch"
          ),
      },

      {
        path: "/gl/account/",
        component: () =>
          import(
            /* webpackChunkName: "gl-account" */ "../views/gl/account/Index.vue"
          ),
      },
      {
        path: "/gl/account/create",
        component: () =>
          import(
            /* webpackChunkName: "gl-account-create" */ "../views/gl/account/Create.vue"
          ),
      },
      {
        path: "/gl/account/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/account/Edit.vue"
          ),
      },
      {
        path: "/gl/account/classes",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Index.vue"
          ),
      },
      {
        path: "/gl/account/classes/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Create.vue"
          ),
      },
      {
        path: "/gl/account/classes/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Edit.vue"
          ),
      },
      {
        path: "/gl/account/group",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Index.vue"
          ),
      },
      {
        path: "/gl/account/group/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Create.vue"
          ),
      },
      {
        path: "/gl/account/group/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Edit.vue"
          ),
      },
      {
        path: "/gl/account/dimensions",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Index.vue"
          ),
      },
      {
        path: "/gl/account/dimensions/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Create.vue"
          ),
      },
      {
        path: "/gl/account/dimensions/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Edit.vue"
          ),
      },
      {
        path: "/customers",
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/customer/Index.vue"
          ),
      },
      {
        path: "/customer/create",
        component: () =>
          import(
            /* webpackChunkName: "customer-create" */ "../views/customer/Create.vue"
          ),
      },
      {
        path: "/customer/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "customer-create" */ "../views/customer/Edit.vue"
          ),
      },
      {
        path: "/vendors",
        component: () =>
          import(/* webpackChunkName: "vendors" */ "../views/vendor/Index.vue"),
      },
      {
        path: "/vendors/create",
        component: () =>
          import(
            /* webpackChunkName: "vendors-create" */ "../views/vendor/Create.vue"
          ),
      },
      {
        path: "/vendors/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "vendors-create" */ "../views/vendor/Edit.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches" */ "../views/customer/branch/Index.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch/create",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches-create" */ "../views/customer/branch/Create.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches-create" */ "../views/customer/branch/Edit.vue"
          ),
      },
      {
        path: "/gl/account/journal",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-index" */ "../views/gl/journal/Index.vue"
          ),
      },
      {
        path: "/gl/account/journal/create",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-create" */ "../views/gl/journal/Create.vue"
          ),
      },
      {
        path: "/gl/account/journal/:id/details",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-details" */ "../views/gl/journal/JournalDetails.vue"
          ),
      },
      {
        path: "/gl/account/journal/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-edit" */ "../views/gl/journal/Edit.vue"
          ),
      },
      {
        path: "/gl/account/gl-inquires",
        component: () =>
          import(
            /* webpackChunkName: "gl-inquires-index" */ "../views/gl/inquires/Index.vue"
          ),
      },
      {
        path: "/gl/account/trial-balance",
        component: () =>
          import(
            /* webpackChunkName: "gl-trial-balance" */ "../views/gl/inquires/TrailBalance.vue"
          ),
      },

      {
        path: "/gl/account/:id/transactions/details",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-details" */ "../views/gl/inquires/AccountLedgerTransactionDetails.vue"
          ),
      },

      {
        path: "/gl/account/bank-reconcile",
        component: () =>
          import(
            /* webpackChunkName: "gl-bank-reconcile" */ "../views/gl/inquires/BankReconcile.vue"
          ),
      },
      {
        path: "/invoice",
        component: () =>
          import(/* webpackChunkName: "Invoice" */ "../views/HvInvoice.vue"),
      },

      {
        path: "/inventory/stock-type",
        component: () =>
          import(
            /* webpackChunkName: "StockTypeView" */ "../views/inventory/stock_type/StockTypeList"
          ),
      },
      {
        path: "/inventory/stock-type/create",
        component: () =>
          import(
            /* webpackChunkName: "StockType-create" */ "../views/inventory/stock_type/CreateStockType"
          ),
      },
      {
        path: "/inventory/stock-type/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "StockType-edit" */ "../views/inventory/stock_type/EditStockType"
          ),
      },

      {
        path: "/inventory/material",
        component: () =>
          import(
            /* webpackChunkName: "MaterialList" */ "../views/inventory/material/MaterialList"
          ),
      },
      {
        path: "/inventory/material/create",
        component: () =>
          import(
            /* webpackChunkName: "Material-create" */ "../views/inventory/material/CreateMaterial"
          ),
      },
      {
        path: "/inventory/material/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "Material-edit" */ "../views/inventory/material/EditMaterial"
          ),
      },
      {
        path: "/inventory/tea-stock",
        component: () =>
          import(
            /* webpackChunkName: "TeaStockList" */ "../views/inventory/tea_stock/TeaStockList"
          ),
      },
      {
        path: "/inventory/tea-stock/create",
        component: () =>
          import(
            /* webpackChunkName: "TeaStock-create" */ "../views/inventory/tea_stock/CreateTeaStock"
          ),
      },
      {
        path: "/inventory/tea-stock/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "TeaStock-edit" */ "../views/inventory/tea_stock/EditTeaStock"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.auth) &&
    !store.getters["auth/isLoggedIn"]
  ) {
    next("/login");
    return;
  }
  next();
});

export default router;
