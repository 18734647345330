<template>
  <el-menu
    class="el-menu-vertical-demo"
    default-active="/dashboard"
    :router="true"
    background-color="#00251A"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <el-menu-item index="/dashboard">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-home icon"
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
        <polyline points="9 22 9 12 15 12 15 22" />
      </svg>
      <span>Dashboard</span>
    </el-menu-item>

    <el-sub-menu
      index="/expenses"
      v-if="checkRole(['super-admin', 'account-manager', 'account-executive'])"
    >
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-dollar-sign icon"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
        <span>Expenses</span>
      </template>
      <el-menu-item index="/expenses">Expense List</el-menu-item>
      <el-menu-item index="/expense-heads">Expense Heads</el-menu-item>
    </el-sub-menu>

    <el-sub-menu
      index="/stock"
      v-if="
        checkRole([
          'super-admin',
          'account-manager',
          'account-executive',
          'support',
        ])
      "
    >
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shopping-bag icon"
        >
          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <path d="M16 10a4 4 0 0 1-8 0"></path>
        </svg>
        <span>Manage stocks </span>
      </template>
      <el-menu-item
        index="/sku"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >SKU</el-menu-item
      >
      <el-menu-item
        index="/category"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Category</el-menu-item
      >
      <el-menu-item
        index="/products"
        v-if="
          checkRole([
            'super-admin',
            'account-manager',
            'account-executive',
            'support',
          ])
        "
        >Product</el-menu-item
      >
      <el-menu-item
        index="/stocks"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Stocks</el-menu-item
      >
      <el-menu-item
        index="/batches"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Batches</el-menu-item
      >
    </el-sub-menu>
    <el-sub-menu
      index="/accounts"
      v-if="
        checkRole([
          'super-admin',
          'account-manager',
          'account-executive',
          'support',
        ])
      "
    >
      <template #title>
        <el-icon :size="16"><Notebook /></el-icon>
        <span>GL & Banking</span>
      </template>
      <el-sub-menu
        index="/gl/account/journal"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
      >
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Transactions</span>
        </template>
        <el-menu-item index="/gl/account/journal">Journal</el-menu-item>
      </el-sub-menu>
      <el-sub-menu
        index="/gl/account/gl-inquires"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
      >
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Inquires & Reports</span>
        </template>

        <el-menu-item
          index="/gl/account/gl-inquires"
          v-if="
            checkRole(['super-admin', 'account-manager', 'account-executive'])
          "
          >GL Inquiry</el-menu-item
        >
        <el-menu-item
          index="/gl/account/bank-reconcile"
          v-if="
            checkRole(['super-admin', 'account-manager', 'account-executive'])
          "
          >Bank Reconcile</el-menu-item
        >
        <el-menu-item index="/gl/account/trial-balance"
          >Trial Balance</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu
        index="/gl/account/classes"
        v-if="checkRole(['super-admin', 'account-manager', 'support'])"
      >
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Settings</span>
        </template>
        <el-menu-item index="/gl/account/group">Account Group</el-menu-item>
        <el-menu-item index="/gl/account/classes">Account Classes</el-menu-item>
        <el-menu-item index="/gl/account">Accounts</el-menu-item>
        <el-menu-item index="/gl/account/dimensions">Dimensions</el-menu-item>
      </el-sub-menu>
    </el-sub-menu>
    <el-menu-item
      index="/customers"
      v-if="checkRole(['super-admin', 'support', 'account-executive'])"
    >
      <el-icon :size="16"><User /></el-icon>
      <span>Customers</span>
    </el-menu-item>

    <el-menu-item index="/vendors" v-if="checkRole(['super-admin', 'support'])">
      <el-icon :size="16"><User /></el-icon>
      <span>Vendors</span>
    </el-menu-item>

    <el-sub-menu
      index="/purchase-orders"
      v-if="
        checkRole([
          'super-admin',
          'account-manager',
          'account-executive',
          'support',
        ])
      "
    >
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shopping-bag icon"
        >
          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <path d="M16 10a4 4 0 0 1-8 0"></path>
        </svg>
        <span>Sales </span>
      </template>
      <el-menu-item
        index="/purchase-orders"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Purchase Orders</el-menu-item
      >
      <el-menu-item
        index="/sales"
        v-if="
          checkRole([
            'super-admin',
            'account-manager',
            'account-executive',
            'support',
          ])
        "
        >Sales</el-menu-item
      >
      <el-menu-item
        index="/collections"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Collection History</el-menu-item
      >
      <el-menu-item
        index="/credit-notes"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Credit Notes</el-menu-item
      >
      <el-menu-item
        index="/invoices"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Invoices</el-menu-item
      >
    </el-sub-menu>

    <el-sub-menu
      index="/vendor-purchase-orders"
      v-if="checkRole(['super-admin', 'account-manager', 'account-executive'])"
    >
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shopping-bag icon"
        >
          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <path d="M16 10a4 4 0 0 1-8 0"></path>
        </svg>
        <span>Purchase Orders </span>
      </template>
      <el-menu-item
        index="/vendor-purchase-orders"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Vendor's PO</el-menu-item
      >
      <el-menu-item
        index="/payments"
        v-if="
          checkRole(['super-admin', 'account-manager', 'account-executive'])
        "
        >Payment History</el-menu-item
      >
    </el-sub-menu>

    <el-sub-menu
      index="/reports"
      v-if="
        checkRole([
          'super-admin',
          'account-manager',
          'account-executive',
          'support',
        ])
      "
    >
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shopping-bag icon"
        >
          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <path d="M16 10a4 4 0 0 1-8 0"></path>
        </svg>
        <span>Reports </span>
      </template>
      <el-menu-item index="/sales">PO & Collection</el-menu-item>
      <el-menu-item index="/invoices">Invoices</el-menu-item>
      <el-menu-item index="/reports/summary">Sales Summary</el-menu-item>
      <el-menu-item index="/reports/customer/trail-balance"
        >Customer TB</el-menu-item
      >
      <el-menu-item index="/reports/customer/balance"
        >Customer Balance</el-menu-item
      >
      <el-menu-item index="/reports/aged-customer/analysis"
        >Aged Cust. Analysis</el-menu-item
      >
    </el-sub-menu>

    <el-sub-menu index="/inventory">
      <template #title>
        <el-icon :size="16"><Notebook /></el-icon>
        <span>Inventory</span>
      </template>
      <el-sub-menu
        index="/inventory/tea-stock">
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Transactions</span>
        </template>
        <el-menu-item index="/inventory/tea-stock">Tea Stock</el-menu-item>
      </el-sub-menu>
      <el-sub-menu
        index="/gl/account/gl-inquires">
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Inquires & Reports</span>
        </template>

        <!-- <el-menu-item
          index="/gl/account/gl-inquires"
          v-if="
            checkRole(['super-admin', 'account-manager', 'account-executive'])
          "
          >GL Inquiry</el-menu-item
        > -->
      </el-sub-menu>
      <el-sub-menu index="/inventory/stock-type">
        <template #title>
          <el-icon :size="16"><Tools /></el-icon>
          <span>Settings</span>
        </template>
        <el-menu-item index="/inventory/stock-type">Stock Type</el-menu-item>
        <el-menu-item index="/inventory/material">Material</el-menu-item>
      </el-sub-menu>
    </el-sub-menu>

    <el-sub-menu index="/settings" v-if="checkRole(['super-admin'])">
      <template #title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-settings icon"
        >
          <circle cx="12" cy="12" r="3" />
          <path
            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
          />
        </svg>
        <span>Settings</span>
      </template>
      <el-menu-item index="/settings/users">Users</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>
<script>
import { Notebook, Tools, User } from "@element-plus/icons-vue";
import { mapGetters } from "vuex";
export default {
  name: "SidebarComp",
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  components: {
    Notebook,
    Tools,
    User,
  },
  methods: {
    checkRole(permittedRoles) {
      if (!this.authUser.role) return false;
      permittedRoles = permittedRoles || [];
      if (permittedRoles.includes(this.authUser.role.unique_name)) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.el-menu-vertical-demo {
  // height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  height: 100%;
}
.el-menu-item,
.el-sub-menu__title {
  .el-icon,
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
</style>
